const XLSX = require('xlsx');

export const generateExcel = ({ rows, header, sheetname }, filename) => {
  let wb = XLSX.utils.book_new()
	let ws = XLSX.utils.json_to_sheet(rows, { header });

  Object.keys(ws).forEach(function(s) {
    if(ws[s].t === 'n') {
        ws[s].z = '0';
        ws[s].t = 's';
    }
  });

	XLSX.utils.book_append_sheet(wb, ws, sheetname);
	XLSX.writeFile(wb, filename);
}

export const generateDefaultFBAShipmentSheets = (totalRows) => {
  let rows = [];
  for(let x=0;x<totalRows;x++){
    rows.push(["", "", ""])
  }
  return rows
}